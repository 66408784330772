/* Refund Policy Styles */
.refund-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f7fa;
    padding: 20px;
  }
  
  .refund-box {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    text-align: left;
  }
  
  h1, h2 {
    color: #0d3a75;
  }
  
  h1 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  
  p {
    font-size: 14px;
    color: #333;
    line-height: 1.6;
  }
  
  ul {
    margin-left: 20px;
  }
  
  .contact-info {
    margin-top: 20px;
    font-weight: bold;
  }
  
  .contact-info p {
    margin: 5px 0;
  }
  
  a {
    color: #2563eb;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  